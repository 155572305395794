<template>
    <div class="btn-group">
        <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOption.name !== undefined">
          {{ selectedOption.name }}
          <slot name="icon">
            <svg
            width="14px" height="14px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 52.4 (67378) - http://www.bohemiancoding.com/sketch -->
                <title>ic_button_deactive_dropdown_active_down</title>
                <desc>Created with Sketch.</desc>
                <g id="ic_button_deactive_dropdown_active_down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect id="Bounds" x="0" y="0" width="10" height="10"></rect>
                    <path d="M3,1.65948276 C3,1.81465517 3.05074922,1.99568966 3.17762226,2.125 L6.52212322,5.5 L3.17762226,8.875 C3.05074922,9.00431034 3,9.15948276 3,9.34051724 C3,9.52155172 3.05074922,9.67672414 3.17762226,9.80603448 C3.43136834,10.0646552 3.83736208,10.0646552 4.06573356,9.80603448 L7.82117562,5.97844828 C7.84655022,5.95258621 7.87192483,5.90086207 7.89729944,5.875 C8.02417248,5.69396552 8.02417248,5.4612069 7.94804866,5.28017241 C7.92267405,5.20258621 7.87192483,5.125 7.82117562,5.07327586 L4.06573356,1.19396552 C3.81198747,0.935344828 3.40599374,0.935344828 3.17762226,1.19396552 C3.05074922,1.32327586 3,1.47844828 3,1.65948276 Z" id="Arrow-" fill="$secondary-black" fill-rule="nonzero" transform="translate(5.500000, 5.500000) rotate(-270.000000) translate(-5.500000, -5.500000) "></path>
                </g>
            </svg>
          </slot>
        </li>

        <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOption.name === undefined">
          {{placeholderText}}
          <span class="caret"></span>
        </li>

        <ul class="dropdown-menu" v-if="showMenu">
            <li v-for="option in options" :key="option.name">
                <a href="javascript:void(0)" @click="updateOption(option)">
                    {{ option.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  data () {
    return {
      selectedOption: {
        name: ''
      },
      showMenu: false,
      placeholderText: 'Please select an item'
    }
  },
  props: {
    options: {
      type: [Array, Object]
    },
    selected: {},
    placeholder: [String]
  },

  mounted () {
    this.selectedOption = this.selected
    if (this.placeholder) {
      this.placeholderText = this.placeholder
    }
  },

  methods: {
    updateOption (option) {
      this.selectedOption = option
      this.showMenu = false
      this.$emit('updateOption', this.selectedOption)
    },

    toggleMenu () {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-group {
  min-width: 160px;
  height: 40px;
  position: relative;
  margin: 0px 1px;
  display: inline-block;
  vertical-align: middle;
}
.btn-group a:hover {
  text-decoration: none;
}

.dropdown-toggle {
  color: $secondary-black;
  min-width: 160px;
  padding: 10px;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background-image: linear-gradient(#009688, #009688);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
}
.dropdown-toggle:hover {
  background: #e1e1e1;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.dropdown-menu > li > a {
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: $secondary-black;
  white-space: nowrap;
  text-decoration: none;
}
.dropdown-menu > li > a:hover {
  background: #E9E9E9;;
  color: $secondary-black;
}

.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}

/* .caret {
  display: relative;
  width: 0;
  position: relative;
  top: 10px;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  float: right;
} */

li {
    list-style: none;

}

svg {
  padding-left: 5px;
  padding-top: 5px;
    }
</style>
