<template>
  <div class="mobile-filters" v-bind:class="{ fixed: dateIsActive || filtersIsActive }">
    <div v-if="hideButtons" class="title">
      <button class="close" @click="handleCloseClick">
        <img src="./../../assets/list-page/bt_map_close_map_default.svg" alt="" srcset="">
      </button>
      <p v-if="filtersIsActive">{{ $t('filters') }}</p>
      <button v-if="filtersIsActive" class="close" @click="handleRestoreFiltersClick">{{ $t('restore').toUpperCase() }}</button>
    </div>
    <button v-if="!hideButtons" class="btn" :class="{selected: filtersIsSelected}" @click="handleFilterClick">
      <img v-if="filtersIsSelected" class="icon" src="../../assets/filters-icons/ic_filters_white.svg" alt="">
      <img v-if="!filtersIsSelected" class="icon" src="../../assets/filters-icons/ic_filters_black.svg" alt="">
    </button>
    <div v-if="!hideButtons" class="links-container">
      <links
        class="links"
        :isMobile="true"
      ></links>
    </div>
    <div v-if="hideButtons" class="full-screen-container">
      <div v-if="filtersIsActive">
        <mobile-filters 
          :congress="congress" 
          :selected="filters" 
          :dates="filteredDate"
          @handleApply="handleApply" 
          @filtersChange="handleFilters"
          @filterDate="handleFilterDate"
          @restoreDate="handleRestoreDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Links from './../Links'
import MobileFilters from './mobile-navbar/MobileFilters'
import {mapGetters} from 'vuex'

export default {
  components: {
    Links,
    MobileFilters
  },
  props: {
    congress: Object
  },
  data: () => {
    return {
      filtersIsActive: false,
      filtersIsSelected: false,
      filters: {
        rooms: '',
        stars: [],
        distance: {
          distanceInKm: 0,
          distanceFromLat: 0,
          distanceFromLng: 0,
          distanceFromType: 'congress',
          isDirty: false
        },
        price: {
          startPrice: 0,
          endPrice: 0
        },
        hotelName: ''
      },
      filteredDate: null,
    }
  },
  methods: {
    handleFilterClick () {
      this.filtersIsActive = !this.filtersIsActive
    },
    handleCloseClick () {
      this.filtersIsActive = false
    },
    handleRestoreFiltersClick () {
      this.filtersIsActive = false
      this.filtersIsSelected = false
      this.filters.rooms = ''
      this.filters.stars = []
      this.filters.distance.distanceFromLat = 0
      this.filters.distance.distanceFromLng = 0
      this.filters.distance.distanceInKm = 0
      this.filters.distance.distanceFromType = 'congress'
      this.filters.distance.isDirty = false
      this.filters.price.startPrice = 0
      this.filters.price.endPrice = 0
      this.filters.hotelName = ''
      this.$emit('filtersChange', { name: 'rooms', value: '' })
      this.$emit('filtersChange', { name: 'stars', value: [] })
      this.$emit('filtersChange', { name: 'distanceFromLat', value: this.filters.distance.distanceFromLat })
      this.$emit('filtersChange', { name: 'distanceFromLng', value: this.filters.distance.distanceFromLng })
      this.$emit('filtersChange', { name: 'distanceFromType', value: this.filters.distance.distanceFromType })
      this.$emit('filtersChange', { name: 'distanceInKm', value: this.filters.distance.distanceInKm })
      this.$emit('filtersChange', { name: 'startPrice', value: 0 })
      this.$emit('filtersChange', { name: 'endPrice', value: 0 })
      this.$emit('filtersChange', { name: 'hotelName', value: '' })
      this.filteredDate = {
        startDate: new Date(new Date(this.site.start_at).toLocaleString('en-US', {timeZone: "Europe/Madrid"})),
        endDate: new Date(new Date(this.site.finish_at).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
      }
      
      this.$emit("restoreDate", { startDate: this.site.start_at, endDate: this.site.finish_at})
    },
    handleApply () {
      this.filtersIsActive = false
    },
    handleFilters (filter) {
      if (filter.name === 'rooms') {
        this.filters.rooms = filter.value
        this.filtersIsSelected = true
      }
      if (filter.name === 'stars') {
        this.filters.stars = filter.value
        this.filtersIsSelected = true
      }
      if (filter.name === 'distanceFromLat' ||
      filter.name === 'distanceFromLng' ||
      filter.name === 'distanceInKm' ||
      filter.name === 'distanceFromType') {
        this.filters.distance[filter.name] = filter.value
        this.filters.distance.isDirty = true
        this.filtersIsSelected = true
      }

      if (filter.name === 'startPrice' ||
      filter.name === 'endPrice') {
        this.filters.price[filter.name] = filter.value
        this.filtersIsSelected = true
      }

      if (filter.name === 'hotelName') {
        this.filters.hotelName = filter.value
        this.filtersIsSelected = true
      }
      this.$emit('filtersChange', filter)
    },
    handleFilterDate (ev) {
      this.filteredDate = ev
      this.$emit('filterDate', ev)
    },
  },
  computed: {
    ...mapGetters({site: 'site'}),
    hideButtons () {
      return this.filtersIsActive
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    cursor: pointer;
  }
  .mobile-filters {
    margin-top: 60px !important;
    padding-left: 20px;
    align-items: center;
    z-index: 20;
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    height: 80px;
    box-shadow: 0px 1px 10px gray;
  }

  .links-container {
    align-self: right;
    overflow-y: scroll;
    flex: 0 1 auto;
    display: flex;
  }

  .links {
    padding-left: 0;
  }

  .fixed {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
  }

  .btn {
    margin-right: 20px;
    height: 45px;
    width: 50px;
    padding: 0;
    flex: 0 0 auto;
    position: relative;
    background-color: lightgray;
  }

  .btn.selected {
    background-color: $primary-black;
  }

  .icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 12.5px;
    right: 15px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 20px;
    padding-right: 20px;
    p {
      padding-left: 10px;
      width: 100%;
      text-align: left;
      line-height: 42px;
      font-weight: bold;
    }
    button {
      padding: 10px 0;
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: #a8a8a8;
      font-weight: bold;
      img {
        margin-left: -5px;
      }
    }
  }

  .full-screen-container {
    position: fixed;
    top: 140px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background-color: white;
    overflow-y: scroll;
    overflow-x:hidden;
  }
</style>
