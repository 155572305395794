import { calculateHotelPriceHelper } from './calculateHotelPrice'

const _deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

// Gets linear distance between two coords
export const getDistanceInKm = (lat1, lng1, lat2, lng2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = _deg2rad(lat2 - lat1) // deg2rad below
  const dlng = _deg2rad(lng2 - lng1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(_deg2rad(lat1)) * Math.cos(_deg2rad(lat2)) *
    Math.sin(dlng / 2) * Math.sin(dlng / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

export const filterByDateRange = (array, startDate, finishDate) => {
  return array.filter(hotel => {
    for (let index = 0; index < hotel.totalAvailability.length; index++) {
      let rooms = hotel.totalAvailability[index].value
      let date = hotel.totalAvailability[index].date
      if (rooms !== 0 && date >= startDate && date <= finishDate) return true
    }
  })
}

export const filterByStars = (array, stars) => {
  return array.filter(hotel => {
    return stars.includes(Number(hotel.stars))
  })
}

export const filterByPrice = (array, startPrice, endPrice) => {
  return array.filter(hotel => {
    return calculateHotelPriceHelper(hotel) >= startPrice && calculateHotelPriceHelper(hotel) <= endPrice
  })
}

export const filterByName = (array, hotelName) => {
  return array.filter(hotel => {
    return hotel.name.toLowerCase().includes(hotelName.toLowerCase())
  })
}


export const filterByDistanceFrom = (array, distance, fromLat, fromLng, type) => {
  if (type === 'congress')
    return array.filter(hotel => { return hotel.km_to_congress <= distance })
  if (type === 'downtown')
    return array.filter(hotel => { return hotel.km_to_center <= distance })
  else {
    return array.filter(hotel => {
      return (Math.round(
          getDistanceInKm(hotel.location.coordinates[1], hotel.location.coordinates[0], fromLat, fromLng)  * 10) / 10)  <= distance
    })
  }

}

export const filterByRoomType = (array, roomTypes) => {
  return array.filter(hotel => {
    for (let roomType in hotel.inventory) {
      const is_standart = hotel.inventory[roomType].name === hotel.name && roomTypes.includes('standart')
      const is_deluxe = hotel.inventory[roomType].name && hotel.inventory[roomType].name.match(/deluxe/gi)
      const is_superior = hotel.inventory[roomType].name && hotel.inventory[roomType].name.match(/superior/gi)
      const is_executive = hotel.inventory[roomType].name && hotel.inventory[roomType].name.match(/executive/gi)
      const is_interior = hotel.inventory[roomType].name && hotel.inventory[roomType].name.match(/interior/gi)
      const is_premium = hotel.inventory[roomType].name && hotel.inventory[roomType].name.match(/premium/gi)

      if (is_standart || is_deluxe || is_superior || is_executive || is_interior || is_premium) {
        return true
      } 
      return false
    }
  })
}

export const filterByAmmenities = (array, amenities) => {
  return array.filter(hotel => {
    let count = 0
    amenities.forEach((amenitie) => {
      if (hotel.amenities.includes(amenitie)) {
        count++
      } else {
        return false
      }
    })
    if (count === amenities.length) return true
  })
}

// Navbar Filters //

export const filterByWalkingDistance = (array) => {
  return array.filter(hotel => {
    return hotel.near_to_congress
  })
}

export const filterByEcoFriendly = (array) => {
  return array.filter(hotel => {
    return hotel.eco_friendly
  })
}

export const filterByCityCenter = (array) => {
  return array.filter(hotel => {
    return hotel.near_to_center
  })
}

export const filterByRecommended = (array) => {
  return array.filter(hotel => {
    return hotel.is_recommended
  })
}
