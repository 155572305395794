<template>
  <div class="filter-tab">
    <div class="filter-tab__btn"  @click="handleTabClick">
      <img src="./../../../../assets/filters-icons/ic_hotel_name_black.svg" alt="" srcset="">
      <p>{{ $t('hotelName') }}</p>
      <img v-if="!isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active.svg" alt="" srcset="">
      <img v-if="isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg" alt="" srcset="">
    </div>
    <transition name="fade">
      <div v-if="isOpen" class="filter-tab__content">
        <div class="input-container">
          <input class="hotel-name-input" :placeholder="$t('hotelName')" type="text" v-model="hotelName" @keyup.enter="onEnterKeyUp">
          <img v-if="!hotelName" class="input-icon" src="@/assets/list-page/ic_hotel_name_filter.svg" alt="">
        </div>
        <ul class="hotel-list">
          <li v-for="hotel in filteredHotels" v-bind:key="hotel.hotelId">
            <p class="hotel-row" @click="onApply" v-html="highlight(hotel.name)"></p>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown'

export default {
  components: {
    Dropdown
  },
  props: {
    congress: Object,
    selected: String
  },
  data: () => {
    return {
      isOpen: false,
      hotelName: '',
      isSelected: false
    }
  },
  methods: {
    handleTabClick () {
      this.isOpen = !this.isOpen
    },
    highlight (name) {
      return name.replace(new RegExp(this.hotelName, 'gi'), match => {
        return '<strong>' + match + '</strong>'
      })
    },
    onApply () {
      this.hotelName = event.target.innerText
      this.$emit('filtered', { name: 'hotelName', value: this.hotelName })
    },
    onEnterKeyUp () {
      this.$emit('filtered', { name: 'hotelName', value: this.hotelName })
    }
  },
  mounted () {
    if (this.selected) {
      this.hotelName = this.selected
      this.isOpen = true
    }
  },
  computed: {
    filteredHotels () {
      let filtered = [...this.$store.getters.hotels]
      if (this.hotelName) {
        filtered = filtered.filter(hotel => {
          return hotel.name.toLowerCase().includes(this.hotelName.toLowerCase())
        })
      }

      return filtered
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-tab {
    border-top: 1px solid lightgray;
    &__btn {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      p {
        flex: 1 0 auto;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
      }
      img {
        width: 10px;
      }
      img:first-child {
        width: 25px;
      }
    }
    &__content {
      font-weight: 100;
      padding: 20px;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .btn-container {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .hotel-list {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 300px;
    overflow: scroll;
    margin-bottom: 10px;
    width: 100%;
  }

  .hotel-row {
    line-height: 36px;
    margin: 0;
    margin: 0 20px;
    cursor: pointer;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    color: #151515;
  }

  .dropdown-button-holder {
    display: flex;
    padding: 0 10px 10px 20px;
  }

  .hotel-row:hover {
    background-color: #E9E9E9;
  }

  .hotel-name-input {
    line-height: 21px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid $secondary-black;
    outline: none;
    border-radius: 5px;
    width: 100%;
    padding: 8px 10px;
  }

  ::placeholder {
    color: #A8A8A8;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: #A8A8A8;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    color: #A8A8A8;
    font-weight: 400;
  }

  .input-container {
    position: relative;
    margin: 20px 0;
    padding: 0 20px;
    width: 100%;
    .input-icon {
      position: absolute;
      right: 30px;
      top: 11px;
    }
  }

  .is-selected {
    z-index: 3;
  }
</style>
