<template>
  <div class="sort-container" v-bind:class="{ 'map-view-activated': isMapView }">
    <p>{{filteredHotels.length}} <span v-if="!isMobile">{{title}}</span></p>
    <div class="category-container" :class="{'map-view': isMapView}">
      <span v-if="!isMobile" class="sort-by">{{ $t('sortBy') }}</span>
      <sort-options v-if="!isMobile" @sorted="onSelectedSort"/>
      <mobile-sort v-if="isMobile" @sorted="onSelectedSort"/>
    </div>
  </div>
</template>

<script>
import SortOptions from '@/components/list-page/SortOptions'
import MobileSort from '@/components/list-page/mobile-navbar/MobileSort'

export default {
  components: {
    SortOptions,
    MobileSort
  },
  data: () => {
    return {
      title: '',
      isMobile: false
    }
  },

  props: {
    filteredHotels: Array,
    isMapView: Boolean
  },

  watch: {
    '$route': function (to) {
      this.setTargetLabel(to)
    }
  },

  created () {
    this.setTargetLabel(this.$route)
  },
  mounted () {
    if (window.innerWidth <= 768) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleScreeResize)
    })
  },
  unmounted () {
    window.removeEventListener('resize', this.handleScreeResize)
  },

  methods: {
    handleScreeResize () {
      if (window.innerWidth <= 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    onSelectedSort (sort) {
      this.$emit('sortChange', sort)
    },
    setTargetLabel (route) {
      const { walking, ecoFriendly, city } = route.query
      if (!this.isMobile) {
        if (walking) {
          this.title = this.$i18n.t('nearVenueHotels').toLowerCase()
        } else if (ecoFriendly) {
          this.title = this.$i18n.t('ecoFriendlyHotels').toLowerCase()
        } else if (city) {
          this.title = this.$i18n.t('aroundCityCenterHotels').toLowerCase()
        } else { 
          this.title = this.$i18n.t('hotelsFound').toLowerCase()
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>

p {
  font-family: 'Frank Ruhl Libre', sans-serif;
  font-size: 25px;
  color: $secondary-black;
  text-align: left;
}

.sort-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
  width: 100%;
  padding-left: 20px;
}

  .map-view-activated {
    flex-direction: column;
    align-items: flex-start;
  }

.category-container {
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.sort-by {
   color: #A8A8A8;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

.map-view {
  padding-left: 25px;
}

@media (max-width: 768px) {

  .sort-container {
    padding-top: 0;
    padding-left: 0;
  }

}

</style>
