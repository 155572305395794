<template>
  <div class="filter-tab">
    <div class="filter-tab__btn"  @click="handleTabClick">
      <img src="./../../../../assets/filters-icons/ic_price_black.svg" alt="" srcset="">
      <p>{{ $t('price') }}</p>
      <img v-if="!isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active.svg" alt="" srcset="">
      <img v-if="isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg" alt="" srcset="">
    </div>
    <transition name="fade">
      <div v-if="isOpen" class="filter-tab__content">
        <div>
          <p class="dropdown-title">{{ $t('pricePerNight') }}</p>
          <p class="subtitle">{{ $t('taxesAndFees') }}</p>
        </div>
        <div class="slider-container">
          <div class="price-bars">
            <div class="price-bar" v-bind:style="{ height: priceBar[0] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[1] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[2] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[3] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[4] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[5] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[6] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[7] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[8] + 'px' }"></div>
            <div class="price-bar" v-bind:style="{ height: priceBar[9] + 'px' }"></div>
          </div>
          <div class="slider" @mouseover="handleSliderRefresh">
            <vue-slider
              ref="priceSlider"
              v-model="value"
              v-bind="options"
            ></vue-slider>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'
import Dropdown from '@/components/Dropdown'

import { calculateHotelPriceHelper } from '@/helpers/calculateHotelPrice'

export default {
  components: {
    vueSlider,
    Dropdown
  },
  props: {
    congress: Object,
    selected: Object
  },
  data: () => {
    return {
      isOpen: false,
      hoverCount: 0,
      prices: [],
      pricesRange: {
      },
      priceBar: [],
      value: [1, 100],
      isActive: false,
      options: {
        lazy: true,
        width: '100%',
        height: 8,
        dotSize: 17,
        min: 1,
        max: 0,
        disabled: false,
        show: true,
        useKeyboard: true,
        tooltip: 'always',
        formatter: '{value}€',
        enableCross: false,
        mergeFormatter: '{value1}€ ~ {value2}€',
        sliderStyle: {
          'backgroundColor': 'black',
          'border': '0.5px solid white',
          'color': 'white'
        },
        tooltipStyle: {
          'backgroundColor': 'transparent',
          'border': 'none',
          'borderColor': 'transparent',
          'color': 'black',
          'fontWeight': 'light'
        },
        processStyle: {
          'backgroundColor': 'black'
        }
      },
      isSelected: false
    }
  },
  mounted () {
    let prices = this.getPricesList()
    
    this.options.min = Math.floor(prices[0])
    this.options.max = Math.ceil(prices[prices.length - 1])
    this.value[0] = this.options.min
    this.value[1] = this.options.max
    this.prices = prices

    const factor = 0.1 * this.options.max
    let countFactor = 1

    let pricesRange = prices.reduce((acum, current) => {
      while (current > factor * countFactor) {
        countFactor++
        acum[factor * countFactor] = 0
      }
      if (current <= factor * countFactor) {
        if (acum[factor * countFactor]) {
          acum[factor * countFactor]++
        } else {
          acum[factor * countFactor] = 1
        }
      }
      return acum
    }, {})

    let keys = Object.keys(pricesRange)

    keys.sort((a, b) => a - b)

    const maxValue = pricesRange[[...keys].sort((a, b) => pricesRange[b] - pricesRange[a])[0]]

    for (let i = 0; i < keys.length; i++) {
      let k = keys[i]
      if (pricesRange[k] <= 120) {
        const barHeight = (pricesRange[k] * 120) / maxValue
        this.priceBar.push(barHeight)
      } else {
        this.priceBar.push(120)
      }
    }
    this.checkFilterOpen ()
  },

  watch: {
    value: {
      handler () {
        if (this.value[0] === 0) {
          this.value[0]++
        }

        this.$emit('filtered', { name: 'startPrice', value: this.value[0] })
        this.$emit('filtered', { name: 'endPrice', value: this.value[1] })
      },
      deep: true
    }
  },

  methods: {
    checkFilterOpen () {
      if (this.selected.startPrice && this.selected.startPrice != this.options.min) {
        this.value[0] = this.selected.startPrice
        this.isOpen = true
      }
      if (this.selected.endPrice && this.selected.endPrice != this.options.max) {
        this.value[1] = this.selected.endPrice
        this.isOpen = true
      }
    },
    getPricesList () {
      let prices = []
      this.$store.getters.hotels.forEach(hotel => {
        prices.push(calculateHotelPriceHelper(hotel))
      })
      prices = prices.sort((a, b) => {
        if (a > b) return 1
        if (a < b) return -1
        return 0
      })
      return prices
    },
    handleTabClick () {
      this.isOpen = !this.isOpen
    },
    handleSliderRefresh () {
      if (!this.hoverCount) this.hoverCount++
      if (this.hoverCount) this.$refs.priceSlider.refresh()
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-tab {
    border-top: 1px solid lightgray;
    &__btn {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      p {
        flex: 1 0 auto;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
      }
      img {
        width: 10px;
      }
      img:first-child {
        width: 25px;
      }
    }
    &__content {
      width: 100%;
      font-weight: 100;
      padding: 20px;
      padding-top: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .checkbox {
        width: 100%;
        margin-bottom: 10px;
        height: 20px;
        & label {
          text-align: left;
          display: inline-block;
          left: 0;
          width: 100%;
          &::before {
            top: 0px;
            left: initial;
            right: 0;
          }
          &::after {
            top: 3px;
            left: initial;
            right: 3px;
          }
        }
      }
      .btn-container {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .dropdown-button-holder {
    display: flex;
    justify-content: space-between;
  }

  .slider-container {
    width: 100%;
    position: relative;
    margin-top: 130px;
    margin-bottom: 70px;
  }

  .price-bars {
    position: absolute;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-end;
    top: -90px;
    padding: 0 10px;
  }

  .price-bar {
    width: 20%;
    background-color: #a8a8a8;
  }

  .dropdown-title {
    text-align: left;
  }

  .subtitle {
    font-size: 16px;
    font-weight: initial;
    color: #a8a8a8;
  }

  .vue-slider[style] {
    height: 4px !important;
  }

  .is-selected {
    z-index: 3;
  }

</style>
