<template>
  <div class="book-with-us">
    <div class="logo-container">
      <img src="@/assets/list-page/ic_housing_bureau_support_black.svg" alt="">
    </div>
    <div class="text-container">
      <h2>{{ $t('advantagesBookingUs') }}</h2>
      <div class="benefits-container">
        <p>{{ $t('onSiteSupport') }}</p>
        <p>{{ $t('ratesCompetitive') }}</p>
        <p>{{ $t('expertiseAndExperience') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.book-with-us {
  display: flex;
  background-color: #F8F8F8;
  margin-top: 30px;
  margin-right: 20px;
  border-radius: 5px;

  .logo-container {
    padding-left: 30px;
  }

  .text-container {
    text-align: left;
    padding-left: 30px;
    width: 100%;

    .benefits-container {
      display: flex;
      justify-content: space-around;

      p::before {
        content: var(--icon-circular-check);
        padding-right: 8px;
      }
    }

  }
}

@media (max-width: 768px) {

  .book-with-us {
    min-height: 268px;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 0;

    .logo-container {
      padding: 0;
    }

    .text-container {
      padding: 0;
      text-align: center;

      .benefits-container {
        flex-direction: column;
        font-size: 14px;
        color: #6E6E6E;
        width: 80%;
        margin: 0 auto;
        text-align: left;

        p {
          margin-bottom: 5px;
        }
      }
    }
  }

}

</style>
