<template>
  <sort-dropdown ref="sortDropdown" :options="arrayOfObjects" :selected="selected" v-on:updateOption="applySort"></sort-dropdown>
</template>

<script>
import SortDropdown from '@/components/list-page/SortDropdown'
export default {
  components: { SortDropdown },
  data: function() {
    return {
      arrayOptions: [
        {
          name: this.$i18n.t('distanceToCongress'),
          value: 'distanceToCongress'
        },
        {
          name: this.$i18n.t('distanceToCity'),
          value: 'distanceToCity'
        },
        {
          name: this.$i18n.t('priceLowest'),
          value: 'lowestPrice'
        },
        {
          name: this.$i18n.t('priceHighest'),
          value: 'highestPrice'
        },
        {
          name: this.$i18n.t('starsLowest'),
          value: 'lowestStar'
        },
        {
          name: this.$i18n.t('starsHighest'),
          value: 'highestStar'
        }
      ],
      selected: {
        name: this.$i18n.t('distanceToCongress'),
        value: 'distanceToCongress'
      },
      popularOption: [{
        name: this.$i18n.t('popular'),
        value: 'popular'
      }],
    }
  },
  computed: {
    arrayOfObjects () {
      if (this.showPopularFilter) {
        return this.arrayOptions.concat(this.popularOption)
      } else {
        return this.arrayOptions
      }
    },
    showPopularFilter () {
      return Object.keys(this.$route.query).length == 0
    },
  },
  methods: {
    applySort (payload) {
      this.selected = payload
    },
    setPopularOption (setPopularFilter) {
      if (setPopularFilter) {
        this.$refs.sortDropdown.updateOption(this.popularOption[0])
      } else {
        this.$refs.sortDropdown.updateOption(this.arrayOptions[0])
      }
    }
  },
  watch: {
    selected: function () {
      const sortCriteria = this.selected.value
      this.$emit('sorted', { name: 'sortCriteria', value: sortCriteria })
    },
    showPopularFilter(newValue) {
      this.setPopularOption(newValue)
    }
  },
  mounted() {
    this.setPopularOption(this.showPopularFilter)
  },
}
</script>
