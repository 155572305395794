<template>
    <div class="filter-tab">
      <div class="filter-tab__btn"  @click="handleTabClick">
        <img src="@/assets/filters-icons/ic_calendar_black.svg" alt="" srcset="">
        <p>{{ showDates() }}</p>
        <img v-if="!isOpen" src="@/assets/filters-icons/ic_button_deactive_dropdown_active.svg" alt="" srcset="">
        <img v-if="isOpen" src="@/assets/filters-icons/ic_button_deactive_dropdown_active_down.svg" alt="" srcset="">
      </div>
      <transition name="fade">
        <div v-if="isOpen" class="filter-tab__content">
          <range-picker v-model="value" :attrs="calendarAttrs"></range-picker>
        </div>
      </transition>
    </div>
</template>

<script>
import RangePicker from '@/components/RangePicker'

export default {
  components: {
    RangePicker
  },
  props: {
    modelValue: Object,
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
  data: () => {
    return {
        calendarAttrs: [
            {
            key: 'today',
            highlight: {
                color: 'gray',
                fillMode: 'outline',
            },
            dates: new Date(),
            },
        ],
        isOpen: false,
    }
  },
  methods: {
    showDates() {
      return `${this.value.start.toLocaleDateString()} - ${this.value.end.toLocaleDateString()}`
    },

    handleTabClick () {
      this.isOpen = !this.isOpen
    },
  }
}
</script>

<style lang="scss" scoped>
  .filter-tab {
    border-top: 1px solid lightgray;
    &__btn {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      p {
        flex: 1 0 auto;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
      }
      img {
        width: 10px;
      }
      img:first-child {
        width: 25px;
      }
    }
    &__content {
      font-weight: 100;
      padding: 20px;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .checkbox {
        width: 100%;
        margin-bottom: 10px;
        height: 20px;
        & label {
          text-align: left;
          display: inline-block;
          left: 0;
          width: 100%;
          &::before {
            top: 0px;
            left: initial;
            right: 0;
          }
          &::after {
            top: 3px;
            left: initial;
            right: 3px;
          }
        }
      }
      .btn-container {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
