<template>
  <div>
    <div class="filter-tab">
      <div class="filter-tab__btn" :class="{ selected: isSelected }" @click="handleTabClick">
        <p>{{ $t('sortBy') }}</p>
      </div>
      <div v-if="isOpen" class="filter-tab__content">
        <div class="title">
          <button class="close" @click="handleCloseClick">
            <img src="./../../../assets/list-page/bt_map_close_map_default.svg" alt="" srcset="">
          </button>
          <p>{{ $t('sortBy') }}</p>
          <button class="close" @click="handleRestoreSortClick">{{ $t('restore').toUpperCase() }}</button>
        </div>
        <div class="sort-row" v-for="option in options" :key="option.id">
          <label :for="option.value" class="container">
            {{option.name}}
            <input
              type="radio"
              name="option.name"
              @click="selectOption(option)"
              :value="option"
              :id="option.value"
              v-model="selected"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="btn-container">
          <button @click="onApply" class="btn btn-apply">{{ $t('apply') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedSort: Object
  },
  data: function() {
    return {
      isOpen: false,
      optionsList: [
        { id: 1, name: this.$i18n.t('distanceToCongress'), value: 'distanceToCongress' },
        { id: 2, name: this.$i18n.t('distanceToCity'), value: 'distanceToCity' },
        { id: 3, name: this.$i18n.t('priceLowest'), value: 'lowestPrice' },
        { id: 4, name: this.$i18n.t('priceHighest'), value: 'highestPrice' },
        { id: 5, name: this.$i18n.t('starsLowest'), value: 'lowestStar' },
        { id: 6, name: this.$i18n.t('starsHighest'), value: 'highestStar' }
      ],
      selected: { id: 1, name: this.$i18n.t('distanceToCongress'), value: 'distanceToCongress' },
      popularOption: [{id: 7, name: this.$i18n.t('popular'), value: 'popular' }],
      isSelected: false
    }
  },
  computed: {
    options () {
      if (this.showPopularFilter) {
        return this.optionsList.concat(this.popularOption)
      } else {
        return this.optionsList
      }
    },
    showPopularFilter () {
      return Object.keys(this.$route.query).length == 0
    },
  },
  mounted () {
    if (this.showPopularFilter) {
      this.selected = this.popularOption[0]
    } else {
      this.selected = this.optionsList[0]
    }
  },

  watch: {
    selected: function () {
      const sortCriteria = this.selected.value
      this.$emit('sorted', { name: 'sortCriteria', value: sortCriteria })
    },
    showPopularFilter(newValue) {
      if (!newValue && this.selected.value == 'popular') {
        this.selected = this.optionsList[0]
      }
      if (newValue) {
        this.selected = this.popularOption[0]
      }
    }
  },
  methods: {
    applySort (payload) {
      this.selected = payload
    },
    handleTabClick () {
      this.isOpen = !this.isOpen
    },
    handleRestoreSortClick () {
      this.selected = this.optionsList[0]
      this.isOpen = false
      this.isSelected = false
      this.$emit('sorted', { name: 'sortCriteria', value: 'distanceToCongress' })
    },
    handleCloseClick () {
      this.isOpen = false
    },
    selectOption (option) {
      this.selected = option
      this.$emit('updateOption', this.selected)
    },
    onApply () {
      this.isOpen = false
      this.isSelected = true
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    cursor: pointer;
  }

  .filter-tab {
    margin-right: -10px;
    &__btn {
      display: flex;
      padding: 10px 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: lightgray;
      border-radius: 5px;
      &.selected {
        background-color: $primary-black;
        color: white;
      }
      p {
        flex: 1 0 auto;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
      img {
        width: 10px;
      }
      img:first-child {
        width: 25px;
      }
    }
    &__content {
      font-weight: 100;
      padding: 20px;
      padding-top: 20px;
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 20;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 20px;
        p {
          padding-left: 10px;
          width: 100%;
          text-align: left;
          line-height: 42px;
          font-weight: bold;
        }
        button {
          padding: 10px 0;
          background-color: transparent;
          border: none;
          font-size: 14px;
          color: #a8a8a8;
          font-weight: bold;
          img {
            margin-left: -5px;
          }
        }
      }
      .btn-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 10px;
        box-shadow: 0px -1px 10px grey;
        background-color: white;
      }

      .btn-apply {
        width: 100%;
        height: 40px;
      }
    }
  }

.sort-row {
  height: 40px;
  & label:first-child {
    margin-top: 20px;
  }
}
  /* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid $primary-black;
  border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $primary-black;
}

</style>
