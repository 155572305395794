<template>
    <div class="filter-tab">
      <div class="filter-tab__btn"  @click="handleTabClick">
        <img src="./../../../../assets/filters-icons/ic_star_black.svg" alt="" srcset="">
        <p>{{ $t('stars') }}</p>
        <img v-if="!isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active.svg" alt="" srcset="">
        <img v-if="isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg" alt="" srcset="">
      </div>
      <transition name="fade">
        <div  v-if="isOpen" class="filter-tab__content">
          <div v-if="oneStarCheckbox" class="checkbox" >
            <input type="checkbox" id="oneStars" v-model="oneStars" @change="handleStarChange">
            <label for="oneStars"><img v-for="index in 1" :src="images.star" :key="index" alt=""></label>
          </div>
          <div v-if="twoStarCheckbox" class="checkbox" >
            <input type="checkbox" id="twoStars" v-model="twoStars" @change="handleStarChange">
            <label for="twoStars"><img v-for="index in 2" :src="images.star" :key="index" alt=""></label>
          </div>
          <div v-if="threeStarCheckbox" class="checkbox" >
            <input type="checkbox" id="threeStars" v-model="threeStars" @change="handleStarChange">
            <label for="threeStars"><img v-for="index in 3" :src="images.star" :key="index" alt=""></label>
          </div>
          <div v-if="fourStarCheckbox" class="checkbox" >
            <input type="checkbox" id="fourStars" v-model="fourStars" @change="handleStarChange">
            <label for="fourStars"><img v-for="index in 4" :src="images.star" :key="index" alt=""></label>
          </div>
          <div v-if="fiveStarCheckbox" class="checkbox" >
            <input type="checkbox" id="fiveStars" v-model="fiveStars" @change="handleStarChange">
            <label for="fiveStars"><img v-for="index in 5" :src="images.star" :key="index" alt=""></label>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>

export default {
  props: {
    selected: Array
  },
  data: () => {
    return {
      isOpen: false,
      images: {
        star: require('./../../../../assets/ic_hotel_card_star_1.svg')
      },
      oneStars: false,
      twoStars: false,
      threeStars: false,
      fourStars: false,
      fiveStars: false,
      oneStarCheckbox: false,
      twoStarCheckbox: false,
      threeStarCheckbox: false,
      fourStarCheckbox: false,
      fiveStarCheckbox: false,
    }
  },
  mounted () {
    this.getStarsList()
    if (this.selected.length) {
      this.isOpen = true
      this.selected.forEach((star) => {
        switch (star) {
          case 1:
            this.oneStars = true
          break
          case 2:
            this.twoStars = true
            break
          case 3:
            this.threeStars = true
            break
          case 4:
            this.fourStars = true
            break
          case 5:
            this.fiveStars = true
            break
          default:
            break
        }
      })
    }
  },
  methods: {
    getStarsList() {
      const starsArray = this.$store.getters.hotels
        .reduce((acummulator, current) => {
          acummulator.push(current.stars)
          return acummulator
        }, [])
        .sort()
      let stars = [...new Set(starsArray)]
      stars.forEach((star) => {
        switch (star) {
          case 1:
            this.oneStarCheckbox = true
          break
          case 2:
            this.twoStarCheckbox = true
            break
          case 3:
            this.threeStarCheckbox = true
            break
          case 4:
            this.fourStarCheckbox = true
            break
          case 5:
            this.fiveStarCheckbox = true
            break
          default:
            break
        }
      })
    },
    handleStarChange () {
      const { oneStars, twoStars, threeStars, fourStars, fiveStars } = this
      const stars = []
      if (oneStars) stars.push(1)
      if (twoStars) stars.push(2)
      if (threeStars) stars.push(3)
      if (fourStars) stars.push(4)
      if (fiveStars) stars.push(5)

      this.$emit('filtered', { name: 'stars', value: stars })
    },
    handleTabClick () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-tab {
    border-top: 1px solid lightgray;
    &__btn {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      p {
        flex: 1 0 auto;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
      }
      img {
        width: 10px;
      }
      img:first-child {
        width: 25px;
      }
    }
    &__content {
      font-weight: 100;
      padding: 20px;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .checkbox {
        width: 100%;
        margin-bottom: 10px;
        height: 20px;
        & label {
          text-align: left;
          display: inline-block;
          left: 0;
          width: 100%;
          &::before {
            top: 0px;
            left: initial;
            right: 0;
          }
          &::after {
            top: 3px;
            left: initial;
            right: 3px;
          }
        }
      }
      .btn-container {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
