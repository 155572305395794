<template>
  <div class="filter-container">

    <date-filter 
      class="filter" 
      v-model="range_dates"
    >
    </date-filter>

    <stars-filter
      class="filter"
      :selected="selected.stars"
      @filtered="onFilterSelected"
    ></stars-filter>

    <location-filter
      class="filter"
      :selected="selected.distance"
      @filtered="onFilterSelected"
    ></location-filter>

    <price-filter
      class="filter"
      :selected="selected.price"
      @filtered="onFilterSelected"
    ></price-filter>

    <hotel-name-filter
      class="filter"
      :selected="selected.hotelName"
      @filtered="onFilterSelected"
    ></hotel-name-filter>

    <div class="btn-container">
      <button @click="onApply" class="btn btn-apply">{{ $t('apply') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import HotelNameFilter from './filters/HotelNameFilter'
import LocationFilter from './filters/LocationFilter'
import PriceFilter from './filters/PriceFilter'
import StarsFilter from './filters/StarsFilter'
import DateFilter from './filters/DateFilter'

export default {
  props: {
    congress: Object,
    selected: Object,
    dates: Object,
  },
  components: {
    HotelNameFilter,
    LocationFilter,
    PriceFilter,
    StarsFilter,
    DateFilter
  },
  data: () => {
    return {
      range_dates: {
        start: null,
        end: null
      },
      defaultStartDate: null,
      defaultEndDate: null,
    }
  },
  computed: {
    ...mapGetters([
      'site'
    ])
  },
  methods: {
    onFilterSelected (filter) {
      this.$emit('filtersChange', filter)
    },
    onApply () {
      if(this.range_dates.start.toLocaleDateString() !== this.defaultStartDate.toLocaleDateString()) {
        this.$emit('filterDate', { startDate: this.range_dates.start, endDate: this.range_dates.end})
      }
      this.$emit('handleApply', true)
    },
    setDates () {
      if (this.dates) {
        this.range_dates.start = this.dates.startDate
        this.range_dates.end = this.dates.endDate
        this.defaultStartDate = new Date(new Date(this.site.start_at).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
        this.defaultEndDate = new Date(new Date(this.site.finish_at).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
      }
      else {
        this.range_dates.start = new Date(new Date(this.site.start_at).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
        this.range_dates.end = new Date(new Date(this.site.finish_at).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
        this.defaultStartDate = this.range_dates.start
        this.defaultEndDate = this.range_dates.end
      }
      
    },
  },
  beforeMount() {
    this.setDates()
  }
}
</script>

<style lang="scss" scoped>
  .filter-container {
    padding-bottom: 60px;
  }

  .btn-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    box-shadow: 0px -1px 10px grey;
    background-color: white;
    z-index: 21;
  }

  .btn-apply {
    width: 100%;
    height: 40px;
  }

</style>
