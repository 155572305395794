<template>
  <div class="filter-tab">
    <div class="filter-tab__btn"  @click="handleTabClick">
      <img src="./../../../../assets/filters-icons/ic_location_black.svg" alt="" srcset="">
      <p>{{ $t('maximumDistance') }}</p>
      <img v-if="!isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active.svg" alt="" srcset="">
      <img v-if="isOpen" src="./../../../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg" alt="" srcset="">
    </div>
    <transition name="fade">
      <div v-if="isOpen" class="filter-tab__content">
        <div class="slider" @mouseover="handleSliderRefresh">
          <vue-slider
            ref="locationSlider"
            v-model="distanceFrom.distance"
            v-bind="options"
          ></vue-slider>
        </div>
        <p class="initial-distance">100m</p>
        <p class="upto-distance">{{ $t('upTo') }} <strong>{{ distanceFrom.distance }}</strong> km</p>
        <div class="distance-from">
          <div class="distance-from__header">
            <input type="radio" id="one" value="congress" v-model="distanceFrom.type">
            <label class="noselect" for="one" :class="{ selected: distanceFrom.type === 'congress' }">{{ $t('congress') }}</label>
            <span>|</span>
            <input type="radio" id="two" value="downtown" v-model="distanceFrom.type">
            <label class="noselect" for="two" :class="{ selected: distanceFrom.type === 'downtown' }">{{ $t('downtown') }}</label>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    vueSlider
  },
  props: {
    congress: Object,
    selected: Object
  },
  data: () => {
    return {
      isOpen: false,
      distanceFrom: {
        type: 'congress',
        lat: 0,
        lng: 0,
        distance: 0
      },
      picked: '',
      hoverCount: 0,
      value: 0,
      options: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 10,
        interval: 0.1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'always',
        tooltipPlacement: 'top',
        tooltipFormatter:'{value} Km',
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: {
          backgroundColor: 'black',
          border: '0.5px solid white',
          color: 'white'
        },
        railStyle: void 0,
        processStyle: { backgroundColor: 'black'  },
        tooltipStyle: {
          backgroundColor: 'transparent',
          border: 'none',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'light'
        },
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      isSelected: false
    }
  },
  mounted () {
    if (this.selected.isDirty) {
      this.distanceFrom.type = this.selected.distanceFromType || 'congress'
      this.distanceFrom.lat = this.selected.distanceFromLat
      this.distanceFrom.lng = this.selected.distanceFromLng
      this.distanceFrom.distance = this.selected.distanceInKm
      this.isOpen = true
    }

    this.$watch('distanceFrom', () => {
      if (this.distanceFrom.type === 'congress') {
        this.distanceFrom.lat = this.$store.getters.filters.distanceFromLat
        this.distanceFrom.lng = this.$store.getters.filters.distanceFromLng
      }

      if (this.distanceFrom.type === 'downtown') {
        this.distanceFrom.lat = this.$store.getters.filters.distanceFromLat
        this.distanceFrom.lng = this.$store.getters.filters.distanceFromLng
      }

      if (this.distanceFrom.distance === 0) {
        this.distanceFrom.distance = 0.1
      }

      this.$emit('filtered', { name: 'distanceFromLat', value: this.distanceFrom.lat })
      this.$emit('filtered', { name: 'distanceFromLng', value: this.distanceFrom.lng })
      this.$emit('filtered', { name: 'distanceInKm', value: this.distanceFrom.distance })
      this.$emit('filtered', { name: 'distanceFromType', value: this.distanceFrom.type })
    }, {
      deep: true
    })
  },

  methods: {
    handleTabClick () {
      this.isOpen = !this.isOpen
    },

    handleSliderRefresh () {
      if (!this.hoverCount) this.hoverCount++
      if (this.hoverCount) this.$refs.locationSlider.refresh()
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-tab {
    border-top: 1px solid lightgray;
    &__btn {
      display: flex;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      p {
        flex: 1 0 auto;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
      }
      img {
        width: 10px;
      }
      img:first-child {
        width: 25px;
      }
    }
    &__content {
      font-weight: 100;
      padding: 20px;
      padding-top: 20px;
      flex-direction: column;
      align-items: flex-start;
      .checkbox {
        width: 100%;
        margin-bottom: 10px;
        height: 20px;
        & label {
          text-align: left;
          display: inline-block;
          left: 0;
          width: 100%;
          &::before {
            top: 0px;
            left: initial;
            right: 0;
          }
          &::after {
            top: 3px;
            left: initial;
            right: 3px;
          }
        }
      }
      .btn-container {
        width: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .dropdown-button-holder {
    display: flex;
    justify-content: space-between;
  }

  .dropdown-title {
    margin-bottom: 50px;
  }

  .distance-from {
    width: 100%;
  }

  .distance-from__header {
    display: flex;
    justify-content: space-around;
  }

  label {
    color: #a8a8a8;
    font-size: 16px;
    font-weight: initial;
    cursor: pointer;
    position: relative;
    margin-left: 25px;
  }

  span {
    margin: 0 25px;
    color: #a8a8a8;
  }

  .initial-distance {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .selected {
    border-bottom: 2px solid $secondary-black;
  }

  input[type=text] {
    width: 100%;
    line-height: 38px;
    font-size: 16px;
    padding-left: 15px;
    border: 1px solid #B6B6B6;
    opacity: 0.5;
    color: #a8a8a8;
    border-radius: 5px;
    outline: none;
    margin-bottom: 10px;
  }

  input[type=text]:focus {
    border: 1px solid $secondary-black;
  }

  input[type=radio] {
    display: none;
  }

  label::before {
    content: url("./../../../../assets/list-page/ic_hotel_name_center_filter.svg");
    position: absolute;
    left: -25px;
  }

  .poi-input-container {
    position: relative;
    margin: 20px 0;
    margin-top: 25px;
    img {
      position: absolute;
      right: 10px;
      top: 11px;
    }
  }

  .poi-input-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0 2px 11px 0 rgba(0,0,0,0.3);
  }

  p.dropdown-row {
    margin: 10px 0;
  }
  .dropdown-row {
    font-weight: initial;
    line-height: 30px;
    padding-left: 20px;
    &:hover {
      background-color: #a8a8a8;
      cursor: pointer;
    }
    p {
      margin: 0;
    }
  }

  .upto-distance {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
  }

    .vue-slider-tooltip {
      opacity: 0 !important;
    }

    .is-selected {
    z-index: 3;
  }

  .error-container {
    height: 20px;
    .error-message {
      line-height: 20px;
      color: red;
      font-weight: lighter;
    }
  }

</style>
